import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import IconWrapper from '@SnackatCafe/snackat-ui/dist/IconWrapper';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import Button from '@SnackatCafe/snackat-ui/dist/Button';

import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import useMobile from 'src/hooks/useMobile/useMobile';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import Map from 'src/components/Map/Map';
import getBranchId from 'src/modules/landing/utils/getBranchId';
import { CountryCode } from 'src/types';

import { COUNTRIES_OPTIONS, VALID_COUNTRIES } from '../constants';
import { useGetGeoData } from '../api/queries';
import { deliveryCountries } from '../deliveryCountries';
import useLocationExistsOnMapData from '../hooks/useLocationExistsOnMapData';
import { mapZone } from '../types';
import getCountryGeoCode from '../utils/getcountryGeoCode';

interface LocationMapProps {
  queryLatLng?: any;
}

const LocationMap: React.FC<LocationMapProps> = ({ queryLatLng }) => {
  const { t } = useTranslation('location');
  const router = useRouter();

  const { language } = useTranslationContext();
  const { isAuthed } = useAuthContext();
  const isMobile = useMobile();
  const {
    userCountry,
    setUserCountry,
    setLatestLocation,
    setCurrentLocation,
    setAddressId,
  } = useLocationContext();

  const inputRef = React.useRef(null);
  const mapRef = React.useRef(null);
  const countryCode = router.query.countryCode as CountryCode;

  const [country] = COUNTRIES_OPTIONS.filter(
    country => country.code === userCountry
  );
  const center = country.center;
  const countryId = country.id;

  const [location, setLocation] = React.useState('');
  const [branchId, setBranchId] = React.useState<number[]>();
  const [newCountry, setNewCountry] = React.useState<CountryCode>();
  const [city, setCity] = React.useState<mapZone>();
  const [area, setArea] = React.useState<mapZone>();
  const [canDeliver, setCanDeliver] = React.useState(false);
  const [changeCountry, setChangeCountry] = React.useState(false);
  const [zoomWarn, setZoomWarn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmLocation, setConfirmLocation] = React.useState(false);

  const cityName = language === 'en' ? city?.name : city?.arName;
  const areaName = language === 'en' ? area?.name : area?.arName;
  const homeUrl = `/home?country=${countryId}&city=${cityName}&area=${areaName}&location=${branchId}`;
  const disableDeliverHere =
    (!canDeliver && !changeCountry) ||
    (zoomWarn && !changeCountry) ||
    (isAuthed && changeCountry);

  const isValidLocation = useLocationExistsOnMapData();
  const { data: geoData } = useGetGeoData({ countryId });

  const onLoad = React.useCallback(
    function callback(map) {
      mapRef.current = map;
      map.data.addGeoJson(deliveryCountries);
      map.data.addGeoJson(geoData);

      map.data.setStyle({
        fillOpacity: 0,
        strokeOpacity: 0,
      });
      checkIsSupportedArea(center.lat, center.lng);
      checkArea(map);
    },
    [userCountry, geoData]
  );

  const checkArea = map => {
    map.addListener('dragend', () => {
      const mapCenter = map.getCenter();
      const lat = mapCenter.lat();
      const lng = mapCenter.lng();
      const country = getCountryGeoCode(lat, lng);
      checkCountry(country);
      checkIsSupportedArea(lat, lng);
    });
  };

  const checkIsSupportedArea = (lat, lng) => {
    const { branchId } = getBranchId(lat, lng, geoData);
    if (!zoomWarn && !!branchId) {
      setCanDeliver(true);
    } else {
      setCanDeliver(false);
    }
  };

  const checkCountry = country => {
    if (VALID_COUNTRIES.includes(country) && userCountry !== country) {
      setChangeCountry(true);
      setNewCountry(country);
    } else {
      setChangeCountry(false);
    }
  };

  const handleChange = event => {
    inputRef.current = event;
  };

  const handlePlacesChanged = (query?: string) => {
    const map = mapRef.current;
    const queryEvent = query ? query : inputRef?.current;
    const location = {
      lat: queryEvent?.getPlace()?.geometry?.location.lat(),
      lng: queryEvent?.getPlace()?.geometry?.location.lng(),
    };
    location.lat && map.moveCamera({ center: location });
    const name = queryEvent?.getPlace()?.name;
    isValidLocation(
      location,
      map,
      setCanDeliver,
      language,
      setLocation,
      setCity,
      setArea,
      name,
      setBranchId
    );
  };

  const handleZoom = map => {
    const zoomLevel = map?.getZoom();
    if (zoomLevel <= 12 && canDeliver) {
      setZoomWarn(true);
    } else {
      setZoomWarn(false);
    }
  };

  const handleMapLocation = () => {
    const mapCenter = mapRef.current.getCenter();
    const lat = mapCenter.lat();
    const lng = mapCenter.lng();
    const map = mapRef.current;
    isValidLocation(
      { lat, lng },
      map,
      setCanDeliver,
      language,
      setLocation,
      setCity,
      setArea,
      undefined,
      setBranchId
    );
    if (changeCountry) {
      router.push(`/${newCountry}`);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setConfirmLocation(true);
      }, 1000);
    }
  };

  const navigateToHome = () => {
    router.push(homeUrl);
    setAddressId('LATEST-MAP-PIN');
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (!changeCountry && VALID_COUNTRIES.includes(countryCode)) {
      setUserCountry(countryCode);
    }
  }, [center, countryCode, changeCountry]);

  React.useEffect(() => {
    const mapCenter = mapRef?.current?.getCenter();
    const lat = mapCenter?.lat();
    const lng = mapCenter?.lng();
    if (!!city?.language && !!area?.language && !!branchId && !!lat && !!lng) {
      setLatestLocation({
        area,
        branchId,
        city,
        countryId,
        mapPin: { lat, lng },
      });
      setCurrentLocation({ city, area });
      setAddressId('LATEST-MAP-PIN');
      if (confirmLocation) {
        navigateToHome();
      }
    }
  }, [city, area, branchId, confirmLocation]);

  const mapStyle = {
    width: isMobile ? '80vw' : '600px',
    height: isMobile ? '70vh' : '500px',
    margin: '0 auto',
  };

  return (
    <>
      {!!geoData && (
        <div className="z-10 m-auto flex w-full flex-col gap-y-3 ">
          <Map
            style={mapStyle}
            center={center}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              clickableIcons: false,
              minZoom: 7,
            }}
            onZoomChanged={handleZoom}
            zoomWarn={zoomWarn}
            onLoad={onLoad}
            handlePlacesChanged={handlePlacesChanged}
            handleAutocompleteChange={handleChange}
            queryLatLng={queryLatLng}
            currentLocation
            autocomplete
          />
          {location && !changeCountry && (
            <div className="m-auto flex w-full items-center justify-center text-xs rtl:text-right md:max-w-[500px] md:gap-x-1 md:text-sm">
              <IconWrapper
                icon={zoomWarn ? null : LocationMarkerIcon}
                size={20}
                className=" text-primary-500"
              />
              <div>
                {canDeliver ? (
                  !zoomWarn && (
                    <>
                      {t('map.deliverTo')} <b>{location}</b>
                    </>
                  )
                ) : (
                  <b>{t('map.CanNotDeliver')}</b>
                )}
              </div>
            </div>
          )}

          <Button
            rounded="full"
            variant="solid"
            size="md"
            disabled={disableDeliverHere}
            onClick={handleMapLocation}
            isLoading={isLoading}
          >
            {changeCountry
              ? 'Change Country'
              : canDeliver
              ? t('buttons.deliverHere')
              : t('map.CanNotDeliver')}
          </Button>
        </div>
      )}
    </>
  );
};

export default LocationMap;
