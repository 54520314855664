import React from 'react';
import { useTranslation } from 'next-i18next';
import { useLocalStorage } from 'react-use';
import { Portal } from '@headlessui/react';
import { Autocomplete } from '@react-google-maps/api';
import Image from '@SnackatCafe/snackat-ui/dist/Image';

import useParams from 'src/hooks/useParams/useParams';
import useDialogActionHandlers from 'src/hooks/useDialogActionHandlers/useDialogActionHandlers';
import { SingleActionParam } from 'src/types';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import useMobile from 'src/hooks/useMobile';
import InstallAppPrompt from 'src/modules/app/components/InstallAppPrompt';
import LoadingLayout from 'src/modules/app/components/LoadingLayout';

import LocationMapDialog from '../components/LocationMapDialog';
import { LocateMeDialogAction, LocateMeDialogParams } from '../urls';
import LocationCountryPicker from '../components/LocationCountryPicker';
import { useGetCountries } from '../api/queries';

const Location: React.FC = () => {
  const { t } = useTranslation('location');
  const { userCountry } = useLocationContext();
  const { isInitialLoading } = useGetCountries();
  const isMobile = useMobile();

  const inputRef = React.useRef(null);
  const [isInstallAppPrompt, setIsInstallPrompt] = useLocalStorage(
    'show-install-app-prompt',
    false
  );

  const [param] = useParams<LocateMeDialogParams>();
  const [open, close] = useDialogActionHandlers<
    LocateMeDialogAction,
    SingleActionParam
  >();

  return isInitialLoading ? (
    <LoadingLayout />
  ) : (
    <>
      <>
        <LocationMapDialog
          isOpen={param.action === 'location-map'}
          onClose={close}
          queryLatLng={inputRef.current}
        />
        <Portal>
          <InstallAppPrompt
            isOpen={!isInstallAppPrompt && isMobile}
            onClose={() => setIsInstallPrompt(true)}
          />
        </Portal>
        <div className="address-root flex h-[100vh] w-screen flex-col items-center md:h-[90vh] ">
          <div className="m-auto flex w-full flex-col  justify-center text-center ">
            <div className="w-full">
              <h1 className="font-hRtl text-2xl text-primary-500 md:text-4xl">
                مرحبا بكم في سناكات
              </h1>
              <h3 className="font-hRtl text-lg text-secondary-500 md:text-2xl">
                الرجاء تحديد موقعك{' '}
              </h3>
              <h4 className="font-hLtr text-base text-primary-500">
                Welcome to Snackat{' '}
              </h4>
              <h5 className="font-hLtr text-sm text-secondary-500 ">
                Please select your location{' '}
              </h5>
              <div
                id="search-container"
                className="m-auto mt-4 flex w-[90vw] items-center justify-between rounded-full border bg-white hover:border-secondary-500 focus:border-secondary-500 focus:outline-none md:w-[600px]"
              >
                <LocationCountryPicker />
                <div className="h-8 w-[1px] bg-primary-500" />
                <Autocomplete
                  onLoad={e => {
                    inputRef.current = e;
                  }}
                  onPlaceChanged={() => {
                    open('location-map');
                  }}
                  restrictions={{ country: userCountry }}
                  fields={['name', 'formatted_address', 'place_id', 'geometry']}
                >
                  <div className="flex w-full items-center justify-between">
                    <Image
                      src="/images/search-pin.svg"
                      width={'20px'}
                      alt="search map icon"
                      className="mx-1"
                    />
                    <input
                      type="text"
                      placeholder={t('map.input.placeholder')}
                      className=" w-full rounded-full border-none bg-white py-4 px-2 outline-none placeholder:text-base focus:border-secondary-500 md:w-72"
                    />
                  </div>
                </Autocomplete>
                <div
                  onClick={() => {
                    open('location-map');
                  }}
                >
                  <Image
                    src="/images/locate-mp.svg"
                    width="45px"
                    alt="compass icon"
                    className="cursor-pointer p-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Location;
