import * as React from 'react';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import Ugent from 'react-ugent';
import { useClickAway } from 'react-use';
import IconWrapper from '@SnackatCafe/snackat-ui/dist/IconWrapper';
import { useTranslation } from 'next-i18next';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import Image from '@SnackatCafe/snackat-ui/dist/Image';
import { XIcon } from '@heroicons/react/outline';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from 'src/constants/constants';
import { primaryColor } from 'src/styles';

import { useTranslationContext } from '../providers/TranslationProvider';

interface InstallAppPromptProps {
  isOpen: boolean;
  onClose: () => void;
}

const InstallAppPrompt: React.FC<InstallAppPromptProps> = props => {
  const { isOpen, onClose } = props;

  const { t } = useTranslation(['landing', 'common']);

  const ref = React.useRef(null);

  const { isRTL } = useTranslationContext();

  useClickAway(ref, () => {
    if (!isOpen) return;
    onClose();
  });

  return (
    <Ugent device="mobile">
      <Transition appear show={isOpen} as="div">
        <Transition.Child
          as="div"
          className="fixed bottom-2 left-2 right-2 z-40"
          enter="transition delay-200 ease-out duration-500"
          enterFrom="transform translate-y-full"
          enterTo="transform translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-full"
        >
          <div
            dir={isRTL ? 'rtl' : 'ltr'}
            ref={ref}
            className={`rounded-md bg-white p-2 text-icons-500 shadow-md ${
              isRTL ? 'font-rtl' : 'font-ltr'
            }`}
          >
            <div className="flex w-full justify-end" onClick={onClose}>
              <IconWrapper icon={XIcon} size={20} className="bg-gray-100/80" />
            </div>
            <div className="m-auto flex flex-col gap-y-4">
              <div className="text-center text-base">
                {t('titles.installApp')}
              </div>
              <div className="flex items-center justify-center">
                <Ugent os="android">
                  <Link href={GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
                    <Button variant="muted" className="w-44">
                      <Image
                        src="/images/googlePlay.svg"
                        width={'176px'}
                        alt="google play"
                        loaderColor={primaryColor}
                      />
                    </Button>
                  </Link>
                </Ugent>
                <Ugent os="ios">
                  <Link
                    href={APP_STORE_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="h-12"
                  >
                    <Button variant="muted" className="w-44">
                      <Image
                        src="/images/appStore.svg"
                        width={'176px'}
                        alt="app store"
                      />
                    </Button>
                  </Link>
                </Ugent>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </Ugent>
  );
};

export default InstallAppPrompt;
