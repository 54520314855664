import { CountryCode } from 'src/types';
import { deliveryCountries } from '../deliveryCountries';

const getCountryGeoCode = (lat, lng): CountryCode => {
  let countryCode = null;

  deliveryCountries?.features?.forEach(feature => {
    const paths = feature?.geometry?.coordinates[0]?.map(
      point => new google.maps.LatLng(point[1], point[0])
    );
    const poly = new google.maps.Polygon({
      paths,
    });
    const isExisted = google?.maps?.geometry?.poly?.containsLocation(
      new google.maps.LatLng(lat, lng),
      poly
    );
    if (isExisted) {
      countryCode = feature?.properties?.code;
    }
  });
  return countryCode;
};

export default getCountryGeoCode;
