import React from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { XIcon } from '@heroicons/react/outline';

import Dialog from 'src/components/Dialog';
import useMobile from 'src/hooks/useMobile';
import Drawer from 'src/components/Drawer';

import LocationMap from './LocationMap';

interface LocationMapDialogProps {
  isOpen: boolean;
  onClose: () => void;
  queryLatLng?: any;
}

const LocationMapDialog: React.FC<LocationMapDialogProps> = ({
  isOpen,
  onClose,
  queryLatLng,
}) => {
  const { t } = useTranslation('location');
  const isMobile = useMobile();

  const content = (
    <>
      <div className="mb-2 flex justify-between">
        <p className="text-lg font-semibold">
          {t('map.dialog.chooseLocation')}
        </p>
        <Button
          variant="text"
          leftIcon={XIcon}
          onClick={onClose}
          className="m-0 p-0"
          iconProps={{ size: 20 }}
        />
      </div>

      <LocationMap queryLatLng={queryLatLng} />
    </>
  );

  return isMobile ? (
    <Drawer
      isOpen={isOpen}
      onClose={() => null}
      className="new-address m-0 rounded-t-3xl"
    >
      {content}
    </Drawer>
  ) : (
    <Dialog isOpen={isOpen} onClose={() => null}>
      <Dialog.Body>{content}</Dialog.Body>
    </Dialog>
  );
};

export default LocationMapDialog;
