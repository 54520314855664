import * as React from 'react';

import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import useMobile from 'src/hooks/useMobile';
import { useGetCountries } from '../api/queries';
import { CountryOption } from '../types';

const useCountriesOptions = (): CountryOption[] => {
  const { language, isRTL } = useTranslationContext();
  const isMobile = useMobile();
  const { data: countries, isInitialLoading } = useGetCountries();

  const options = React.useMemo(
    () =>
      countries?.map(country => ({
        label: (
          <div
            className={`flex w-full items-center justify-start gap-x-2 bg-white hover:bg-opacity-100 ${
              isRTL && 'font-rtl'
            }`}
            data-cy="cy-user-country"
          >
            <div className=" w-5">
              <img
                src={country.flag}
                alt={country.name}
                className="h-5 w-full rounded-full object-fill"
              />
            </div>
            <div className="w-32 text-sm md:font-light">
              {language === 'en' ? country.name : country.arName}
            </div>
          </div>
        ),
        innerLabel: isMobile && (
          <div
            className=" flex w-full items-center justify-start gap-x-2 bg-white hover:bg-opacity-100"
            data-cy="cy-user-country"
          >
            <div className=" w-5">
              <img
                src={country.flag}
                alt={country.name}
                className="h-5 w-full rounded-full object-fill"
              />
            </div>
          </div>
        ),
        value: country.code,
        disabled: country?.commingSoon,
        note:
          language === 'ar'
            ? country?.arEcommerceMessage
            : country.ecommerceMessage,
        status: country?.ecommerceStatus,
      })),
    [language, isMobile, isInitialLoading, countries]
  );

  return options;
};

export default useCountriesOptions;
