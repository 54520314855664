import * as React from 'react';
import { useTranslation } from 'next-i18next';
import SelectInput from '@SnackatCafe/snackat-ui/dist/SelectInput';

import { selectedOption } from 'src/utils';
import { clearBasket } from 'src/redux/cart/cartSlice';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import useNavigator from 'src/hooks/useNavigator';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';

import useCountriesOptions from '../hooks/useCountriesOptions';
import { useForm } from 'react-hook-form';
import { CountryOption, saveAddressFormValues } from '../types';
import { useDispatch } from 'react-redux';
import { useAppStateContext } from 'src/modules/app/providers/AppStateProvider';

const LocationCountryPicker: React.FC = () => {
  const { t } = useTranslation('location');
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigator();
  const { userCountry, latestLocation } = useLocationContext();
  const { notify } = useAppStateContext();

  const { watch, setValue } = useForm<saveAddressFormValues>({
    defaultValues: {
      country: userCountry,
    },
  });

  const clearCart = () => {
    dispatch(clearBasket());
  };

  const { isAuthed } = useAuthContext();
  const { isRTL } = useTranslationContext();
  const countriesOptions = useCountriesOptions();
  const isLocationRoute = router.pathname === '/';

  React.useEffect(() => {
    if (isLocationRoute && !latestLocation?.city) {
      navigate(`/${watch('country')}`, { preserveQs: false });
    }
  }, [isLocationRoute, latestLocation?.city]);

  React.useEffect(() => {
    if (userCountry) {
      setValue('country', userCountry);
    }
  }, [userCountry]);

  return (
    <div>
      <SelectInput
        value={
          selectedOption(watch('country'), countriesOptions) as CountryOption
        }
        options={countriesOptions}
        label={t('labels.country')}
        onChange={(selected: CountryOption) => {
          if (selected.status) {
            navigate(`/${selected.value}`, { preserveQs: false });
            setValue('country', selected.value);
            clearCart();
          } else {
            notify({ message: selected.note, status: 'error' });
          }
        }}
        className={`cy-country-select-input max-h-12 w-full rounded-full border-none rtl:text-base`}
        disabled={isAuthed}
        isLabelPersistent={false}
        dir={isRTL ? 'rtl' : 'ltr'}
        drawerProps={{
          className: 'rounded-t-3xl',
        }}
      />
    </div>
  );
};

export default LocationCountryPicker;
